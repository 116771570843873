<template>
  <div>


    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>固定资产</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card>
      <!--搜索与添加区域-->
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.fa_name" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-button type="primary" @click="addFixedassetsDialog" icon="el-icon-document-add" v-if="is_add">添加</el-button>
        </el-col>
      </el-row>

      <!--   用户列表区域   -->
      <el-table :data="fixedassetslist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="资产名称" prop="fa_name" show-overflow-tooltip ></el-table-column>
        <el-table-column label="资产价格" prop="fa_price" show-overflow-tooltip></el-table-column>
        <el-table-column label="资产序列号" prop="fa_serial_number" show-overflow-tooltip width="180px"></el-table-column>
        <el-table-column label="单位" prop="fa_company" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="280px">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button type="primary" size="mini" icon="el-icon-edit"
                       @click="fixedassetsEditDialog(scope.row.fa_id)" v-if="is_edit">
            </el-button>
            <!--删除-->
            <el-button type="danger" size="mini" icon="el-icon-delete-solid"
                       @click="removeFixedassetsById(scope.row.fa_id)" v-if="is_del">
            </el-button>

          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加用户的对话框   -->
    <el-dialog
        title="添加固定资产" :visible.sync="addFixedassetsDialogVisible" width="60%" @close="addDialogClosed">
      <!--      内容主题区-->
      <el-form :model="addfixedassetsForm" :rules="addfixedassetsFormRules"
               ref="addFormRef" label-width="100px" >
        <el-form-item label="资产名称" prop="fa_name">
          <el-input v-model="addfixedassetsForm.fa_name"></el-input>
        </el-form-item>
        <el-form-item label="资产型号" prop="fa_model">
          <el-input v-model="addfixedassetsForm.fa_model"></el-input>
        </el-form-item>
        <el-form-item label="原始金额" prop="fa_price">
          <el-input v-model="addfixedassetsForm.fa_price"></el-input>
        </el-form-item>
        <el-form-item label="资产序列号" prop="fa_serial_number">
          <el-input v-model="addfixedassetsForm.fa_serial_number"></el-input>
        </el-form-item>
        <el-form-item label="计量单位" prop="fa_company">
          <el-input v-model="addfixedassetsForm.fa_company"></el-input>
        </el-form-item>
        <el-form-item label="购买日期" prop="fa_buy_date">
          <el-date-picker v-model="addfixedassetsForm.fa_buy_date" class="p_date"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="资产状态" prop="fa_status">
          <el-select v-model="addfixedassetsForm.fa_status" placeholder="请选择"
                     clearable class="m_department">
            <el-option
                v-for="item in list"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用人" prop="m_id">
          <el-select v-model="addfixedassetsForm.m_id" placeholder="请选择"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="fa_remark">
          <el-input v-model="addfixedassetsForm.fa_remark"></el-input>
        </el-form-item>
      </el-form>
      <!--      底部区-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addFixedassetsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFixedassets" :loading="addloading">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加用户的对话框   -->
    <el-dialog
        title="修改固定资产" :visible.sync="editFixedassetsDialogVisible" width="60%" @close="addDialogClosed">
      <!--      内容主题区-->
      <el-form :model="editfixedassetsForm" :rules="addfixedassetsFormRules"
               ref="addFormRef" label-width="100px" >
        <el-form-item label="资产名称" prop="fa_name">
          <el-input v-model="editfixedassetsForm.fa_name"></el-input>
        </el-form-item>
        <el-form-item label="资产型号" prop="fa_model">
          <el-input v-model="editfixedassetsForm.fa_model"></el-input>
        </el-form-item>
        <el-form-item label="原始金额" prop="fa_price">
          <el-input v-model="editfixedassetsForm.fa_price"></el-input>
        </el-form-item>
        <el-form-item label="资产序列号" prop="fa_serial_number">
          <el-input v-model="editfixedassetsForm.fa_serial_number"></el-input>
        </el-form-item>
        <el-form-item label="计量单位" prop="fa_company">
          <el-input v-model="editfixedassetsForm.fa_company"></el-input>
        </el-form-item>
        <el-form-item label="购买日期" prop="fa_buy_date">
          <el-date-picker v-model="editfixedassetsForm.fa_buy_date" class="p_date"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="资产状态" prop="fa_status">
          <el-select v-model="editfixedassetsForm.fa_status" placeholder="请选择"
                     clearable class="m_department">
            <el-option
                v-for="item in list"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用人" prop="m_id">
          <el-select v-model="editfixedassetsForm.m_id" placeholder="请选择"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="fa_remark">
          <el-input v-model="editfixedassetsForm.fa_remark"></el-input>
        </el-form-item>
      </el-form>
      <!--      底部区-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editFixedassetsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editFixedassets" :loading="editloading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// export default {
//   data() {
//     return{
//
//     }
//   },
//   methods: {}
// }
export default {
  data() {
    return {

      addloading:false,
      editloading:false,
      //固定资产列表
      fixedassetslist:[],
      userlist:[],
      list:[{id:1,name:'已使用'},{id:2,name:'未使用'}],
      //
      querInfo: {
        page: 1,
        limit: 10,
        fa_name:''
      },
      total:0,
      //页面权限
      FixedassetsJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      //表格加载
      loading:true,
      //控制添加窗口的显示与隐藏
      addFixedassetsDialogVisible:false,
      editFixedassetsDialogVisible:false,
      //添加固定资产form表单
      addfixedassetsForm:{
        fa_buy_date:this.$store.state.daydate
      },
      editfixedassetsForm:{

      },
      addfixedassetsFormRules:{
        fa_name:[
          {required: true, message: '请输入资产名称', trigger: 'blur'}
        ],
        // fa_model:[
        //   {required: true, message: '请输入资产型号', trigger: 'blur'}
        // ],
        // fa_price:[
        //   {required: true, message: '请输入原始金额', trigger: 'blur'}
        // ],
        // fa_serial_number:[
        //   {required: true, message: '请输入资产序列号', trigger: 'blur'}
        // ],
        // fa_company:[
        //   {required: true, message: '请输入计量单位', trigger: 'blur'}
        // ],
        // fa_buy_date:[
        //   {required: true, message: '请选择购买日期', trigger: 'change'}
        // ],
        // fa_status:[
        //   {required: true, message: '请选择资产状态', trigger: 'change'}
        // ],
        // m_id:[
        //   {required: true, message: '请选择使用人', trigger: 'change'}
        // ],
        // fa_remark:[
        //   {required: true, message: '请输入备注', trigger: 'blur'}
        // ]
      }
    }
  },
  created() {
    this.getFixedassetsList()
    this.getuserList()
    this.getFixedassetsJurisdiction()
  },
  methods: {
    //获取权限接口
    async getFixedassetsJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.FixedassetsJurisdiction=res.data
      this.FixedassetsJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }
      })
    },
    //获取固定资产列表
    async getFixedassetsList() {
      this.loading=true
      const {data: res} = await this.$http.get('fixedassets/getList',{params: this.querInfo})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.fixedassetslist = res.data.data
      this.total = res.data.total
      this.loading=false

    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getUserList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getUserList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getUserList()
    },
    addFixedassetsDialog(){
      this.addFixedassetsDialogVisible=true
    },
    async addFixedassets(){
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('fixedassets/add', this.addfixedassetsForm)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.addFixedassetsDialogVisible = false
        this.getFixedassetsList()
      })
    },
    addDialogClosed(){
      this.$refs.addFormRef.resetFields()

    },
    async fixedassetsEditDialog(id){
      const {data:res} = await this.$http.get(`fixedassets/find/${id}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.editfixedassetsForm = res.data
      this.editFixedassetsDialogVisible=true

    },
    async editFixedassets(){
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        this.editloading=true
        const {data:res} = await this.$http.post('fixedassets/edit',this.editfixedassetsForm)
        this.editloading=false
        if (res.code!==200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.editFixedassetsDialogVisible=false
        this.getFixedassetsList()
      })
    },
    //根据id删除用户数据
    async removeFixedassetsById(id) {
      //弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
          '此操作将永久删除该固定资产, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)

      //如果用户确认删除， 则返回值为字符串 confirm
      //如果用户取消删除，则返回值为字符串 cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data: res} = await this.$http.post(`fixedassets/del/${id}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.getFixedassetsList()
    },
  }
}

</script>
